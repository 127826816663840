import React from 'react';
import SEO from '../components/seo';
import Layout from '../components/layout';

function NotFoundPage() {
  return React.createElement(Layout, null, React.createElement(SEO, {
    title: "404: Not found"
  }), React.createElement("div", null, React.createElement("h2", {
    className: "bg-yellow-400 text-2xl font-bold inline-block my-8 p-3"
  }, "Looks like this page is a ghost that got abducted by aliens...")));
}

export default NotFoundPage;